import './App.css';
import {useRef} from 'react';
import { useState, useEffect } from 'react';
import logo from './Assets/logoo.png'
import './component/Navbar.css'
import { FaBars } from "react-icons/fa6";
import { RxCross2 } from "react-icons/rx";
import { IoArrowBack } from "react-icons/io5";
import { motion} from "framer-motion"
import './component/Footer.css'
import xIcon from './Assets/icons/X.svg'
import instagramIcon from './Assets/icons/instagram.svg'
import facebookIcon from './Assets/icons/facebook.svg'
import linkedInIcon from './Assets/icons/linkedin.svg'
import './component/Herotag.css'
import growth from './Assets/bro.svg'
import './component/Services.css'
import './component/Industry.css'
import icon1 from './Assets/icons/wired-flat-19-magnifier-zoom-search-in-reveal (1).gif'
import icon2 from './Assets/icons/wired-flat-981-consultation-in-reveal.gif'
import icon3 from './Assets/icons/wired-lineal-37-approve-checked-simple-in-reveal.gif'
import icon4 from './Assets/icons/wired-lineal-742-code-in-reveal.gif'
import icon5 from './Assets/icons/wired-flat-499-clipboard-film-clap-in-reveal.gif'
import icon6 from './Assets/icons/graphics-color.gif'
import icon7 from './Assets/icons/wired-lineal-62-film-in-reveal.gif'
import icon8 from './Assets/icons/wired-flat-61-camera-in-reveal.gif'
import icon9 from './Assets/icons/wired-lineal-1037-vlog-camera-in-reveal.gif'
import './component/Portfolio.css';
import './component/About.css';
import './component/Card.css'
import arrow from './Assets/icons/Circle-arrow.svg'
import procees from './Assets/icons/Group 5.svg'
import Testimonial from './Testimonial';
import rating from './Assets/icons/wired-flat-237-star-rating-in-dynamic.gif'
import none from './Assets/icons/wired-flat-237-star-rating-in-dynamicc.gif'
import './component/form.css';
import formTut from './Assets/panaa.svg';
import './component/FAQ.css';
import './Pricing.css'
import './component/PP.css'
import heroText from './Assets/website-text.gif'
import citLogo from './Assets/TrustedBy/cit_logo.png'
import capLogo from './Assets/TrustedBy/capLogo.svg'
import solLogo from './Assets/TrustedBy/SOLECRAFTS_200x.svg'
import stoLogo from './Assets/TrustedBy/cropped-cropped-stockify-wealth.webp'
import karalLogo from './Assets/TrustedBy/Karal_Logo.png'
//services
import SEO from './component/Services/SEO';
import SMOM from './component/Services/SMOM'
import PPC from './component/Services/PPC'
import WD from './component/Services/WebDev'
import CM from './component/Services/CM'
import VP from './component/Services/VP'
import GD from './component/Services/GD'
import MP from './component/Services/MP'
import BS from './component/Services/BS'
//Industries
import SaaS from './component/Industries/SaaS';
import B2B from './component/Industries/B2B';
import B2C from './component/Industries/B2C';
import ECOM from './component/Industries/eC'
//Portfolio Images/Videos
import p1 from './Assets/PortfoliolData/orca website reel.mp4'
import p2 from './Assets/PortfoliolData/for website-1.mp4'
import p4 from './Assets/PortfoliolData/product design 2.jpg'
import p3 from './Assets/PortfoliolData/product design 4.jpg'
import p5 from './Assets/PortfoliolData/shoes poster 2.jpg'
import p6 from './Assets/PortfoliolData/shoes poster 4.jpg'
//Pricing-Card Icons
import cross from './Assets/icons/cross.png'
import check from './Assets/icons/check.png'
import { FaArrowDown } from "react-icons/fa6";
//Backend
import axios from 'axios';
const FAQ = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className='faq-div'>
      <dt onClick={() => setIsOpen(!isOpen)} style={{ cursor: 'pointer' }} className='faq-question'>
        <p><span>{faq.question}</span><span>{isOpen ? '-' : '+'}</span></p>
      </dt>
      {isOpen && <dd className='faq-answer'>{faq.answer}</dd>}
    </div>
  );
};

function App() {

  const pricingDetails = [
    {
      name: 'Basic',
      planDetails: [
        {
          planName: 'SMM',
          sections: [
            { text: 'Account Management - 2', icon: check },
            { text: 'Meta ads: No Of Campaign - 4', icon: check },
            { text: 'Landing Page', icon: cross},
          ],
        },
        {
          planName: 'SEO',
          sections: [
            { text: 'Local SEO', icon: check},
            { text: 'On Page SEO', icon: cross },
            { text: 'Off Page SEO', icon: cross},
            { text: 'SEO Optimization Report', icon: cross},
          ],
        },
        {
          planName: 'PPC',
          sections: [
            { text: 'Google ads (this will not be available if you choose Meta ads)- 2 campaign', icon: check },
            { text: 'Key words - upto 50 words', icon: check },
            { text: 'Landing page', icon: cross },
          ],
        },
        {
          planName: 'Graphic Design',
          sections: [
            { text: 'Branding', icon: check },
            { text: 'SMM Design', icon: check },
            { text: 'Email Letter Design', icon: cross},
            { text: 'Print Design', icon: cross},
          ],
        },
        {
          planName: 'Media Production',
          sections: [
            { text: 'Professional Shoot', icon: check },
            { text: 'Advertisement Shoot', icon: cross},
            { text: 'Scripting', icon: cross},
          ],
        },
        {
          planName: 'Video Editing',
          sections: [
            { text: 'Professional Editing', icon: check },
          ],
        },
        {
          planName: 'Content Marketing',
          sections: [
            { text: 'SMM', icon: check},
            { text: 'Blog', icon: cross},
            { text: 'Article', icon: cross},
            {text: 'Scripting', icon: cross}
          ]
        },
        {
          planName: 'Web Development',
          sections: [
            {text: 'Website Design', icon: cross},
            {text: 'Website Development', icon: cross},
            {text: 'Database Mangement', icon: cross},
            {text: 'Testing & Launch', icon: cross}
          ]
        }
      ],
    },
    {
      name: 'Standard',
      planDetails: [
        {
          planName: 'SMM',
          sections: [
            { text: 'Account Management - 3', icon: check },
            { text: 'Meta ads: No Of Campaign - 10', icon: check },
            { text: 'Landing Page', icon: cross},
          ],
        },
        {
          planName: 'SEO',
          sections: [
            { text: 'Local SEO', icon: check},
            { text: 'On Page SEO', icon: cross },
            { text: 'Off Page SEO', icon: check},
            { text: 'SEO Optimization Report', icon: cross},
          ],
        },
        {
          planName: 'PPC',
          sections: [
            { text: 'Google ads- 7 campaign', icon: check },
            { text: 'Key words - upto 100 words', icon: check },
            { text: 'Landing page (extra charges)', icon: check },
          ],
        },
        {
          planName: 'Graphic Design',
          sections: [
            { text: 'Branding', icon: check },
            { text: 'SMM Design', icon: check },
            { text: 'Email Letter Design', icon: cross},
            { text: 'Print Design', icon: check },
          ],
        },
        {
          planName: 'Media Production',
          sections: [
            { text: 'Professional Shoot', icon: check },
            { text: 'Advertisement Shoot', icon: check },
            { text: 'Scripting', icon: cross},
          ],
        },
        {
          planName: 'Video Editing',
          sections: [
            { text: 'Professional Editing', icon: check },
          ],
        },
        {
          planName: 'Content Marketing',
          sections: [
            { text: 'SMM', icon: check},
            { text: 'Blog', icon: check},
            { text: 'Article', icon: check},
            {text: 'Scripting', icon: cross}
          ]
        },
        {
          planName: 'Web Development',
          sections: [
            {text: 'Website Design', icon: cross},
            {text: 'Website Development', icon: cross},
            {text: 'Database Mangement', icon: cross},
            {text: 'Testing & Launch', icon: cross}
          ]
        }
      ],
    },
    {
      name: 'Business',
      planDetails: [
        {
          planName: 'SMM',
          sections: [
            { text: 'Account Management - 5', icon: check },
            { text: 'Meta ads: No Of Campaign - As required', icon: check },
            { text: 'Landing Page', icon: check },
          ],
        },
        {
          planName: 'SEO',
          sections: [
            { text: 'Local SEO', icon: check},
            { text: 'On Page SEO', icon: check },
            { text: 'Off Page SEO', icon: check},
            { text: 'SEO Optimization Report', icon: check},
          ],
        },
        {
          planName: 'PPC',
          sections: [
            { text: 'Google ads (any ads)- as required', icon: check },
            { text: 'Key words - upto 1000 words', icon: check },
            { text: 'Landing page - 5 (extra charges for more than 5)', icon: check },
          ],
        },
        {
          planName: 'Graphic Design',
          sections: [
            { text: 'Branding', icon: check },
            { text: 'SMM Design', icon: check },
              { text: 'Email Letter Design', icon: check },
              { text: 'Print Design', icon: check },
            ],
          },
          {
            planName: 'Media Production',
            sections: [
              { text: 'Professional Shoot', icon: check },
              { text: 'Advertisement Shoot', icon: check },
              { text: 'Scripting', icon: check },
            ],
          },
          {
            planName: 'Video Editing',
            sections: [
              { text: 'Professional Editing', icon: check },
            ],
          },
          {
            planName: 'Content Marketing',
            sections: [
              { text: 'SMM', icon: check},
              { text: 'Blog', icon: check},
              { text: 'Article', icon: check},
              {text: 'Scripting', icon: check}
            ]
          },
          {
            planName: 'Web Development',
            sections: [
              {text: 'Website Design', icon: check},
              {text: 'Website Development', icon: check},
              {text: 'Database Mangement', icon: check},
              {text: 'Testing & Launch', icon: check}
            ]
          }
        ],
      },
    ];
  const trustedByData = [
    {src: citLogo},
    {src: capLogo},
    {src: solLogo},
    {src: stoLogo},
    {src: karalLogo}
  ]
  const mediaData = [
    { type: 'video', src: p1, alt: 'Image 1' },
    { type: 'image', src: p4, alt: 'Image 2' },
    { type: 'image', src: p3, alt: 'Image 3' },
    { type: 'video', src: p2, alt: 'Image 4' },
    { type: 'image', src: p5, alt: 'Image 5' },
    { type: 'image', src: p6, alt: 'Image 6' }
  ];

  const CardData = [
    {
        customerName: "Dave Jangid, Capsitech CEO",
        rating: rating,
        rating1: rating,
        rating2: rating,
        rating3: rating,
        rating4: rating,
        description: "Orca Creations provided exceptional professional shoot services that left us thoroughly impressed. Their team demonstrated remarkable skill and creativity in capturing our project's essence. From meticulous planning to flawless execution, every detail was handled with professionalism and expertise."
    },
    {
      customerName: "Piyush Jhunjhunwala, Stockify CEO & FOUNDER",
      rating: rating,
      rating1: rating,
      rating2: rating,
      rating3: rating,
      rating4: rating,
      description: "Orca Creations provided an exceptional blend of digital marketing services and video editing that elevated our brand's online presence to new heights."
    },
    {
      customerName: "Atul Mehta, Solecraft Founder",
      rating: rating,
      rating1: rating,
      rating2: rating,
      rating3: rating,
      rating4: rating,
      description: "Orca Creations provided exceptional digital marketing services that surpassed our expectations. Their team's expertise in social media management significantly boosted our online presence. They are professional, and delivered measurable results."
    }
  ]

  const [isActive, setIsActive] = useState(true);
  const handleClick = () => {
    setIsActive((prev) => !prev);
  };
  const [isPpActive, setIsPpActive] = useState(true);
  const handleClickPP = () => {
    setIsPpActive((prev) => !prev);
  };
  const [activeCard, setActiveCard] = useState(null);
  const [activeCard2, setActiveCard2] = useState(null);

  const handleCardClick = (cardName) => {
    setActiveCard((prevCard) => (prevCard === cardName ? null : cardName)); // Toggle card
  };
  const handleCardClick2 = (cardName) => {
    setActiveCard2((prevCard) => (prevCard === cardName ? null : cardName)); // Toggle card
  };
  const handleClose = () => {
    setActiveCard(null);
  };
  const handleClose2 = () => {
    setActiveCard2(null);
  };
  const service = useRef(null);
  const form = useRef(null);
  const home = useRef(null);
  const formm = useRef(null);
  const portfolio = useRef(null);
  const industry = useRef(null);
  const pricing = useRef(null);
  const engage = useRef(null);
  const cardhead = useRef(null);
  const card2head = useRef(null);
  const top = useRef(null);
  const srvic = useRef(null);
  const ind = useRef(null);
  
  const sections = {
    services: service,
    form: form,
    home: home,
    formm: formm,
    portfolio: portfolio,
    industry: industry,
    pricing: pricing,
  };

  // Generalized scroll function with customizable offset
  const scrollToSection = (sectionName, offset = 0) => {
    const sectionRef = sections[sectionName];
    if (sectionRef && sectionRef.current) {
      const { top } = sectionRef.current.getBoundingClientRect();
      const scrollPosition = window.scrollY + top + offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
    setIsActive((prev) => !prev);
  };

  const cardHead = () => {
    cardhead.current?.scrollIntoView({behavior: 'smooth'});
  }
  const card2Head = () => {
    card2head.current?.scrollIntoView({behavior: 'smooth'});
  }
  const Top = () => {
    top.current?.scrollIntoView({behavior: 'smooth'});
  }
  const Contact = () => {
    engage.current?.scrollIntoView({behavior: 'smooth'});
  }
  const Srvc = () => {
    srvic.current?.scrollIntoView({behavior: 'smooth'});
  }
  const Ind = () => {
    ind.current?.scrollIntoView({behavior: 'smooth'});
  }

  const [formData, setFormData] = useState({ 
    name: "", 
    company: "", 
    phone: "", 
    email: "", 
    message: "" 
});
const [checkedItems, setCheckedItems] = useState({ 
    SEO: false, 
    SMOM: false, 
    PPC: false, 
    MP: false, 
    Other: false 
});
const [errors, setErrors] = useState({});
const [isSubmitting, setIsSubmitting] = useState(false);
const [isPopupOpen, setIsPopupOpen] = useState(false);
const [popupMessage, setPopupMessage] = useState("");
const [popupType, setPopupType] = useState(""); 

const closePopUp = () => setIsPopupOpen(false);

const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    if (type === "checkbox") {
        setCheckedItems(prev => ({ 
            ...prev, 
            [name]: checked 
        }));
        // Clear any checkbox-related errors
        if (errors.services) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors.services;
                return newErrors;
            });
        }
    } else {
        setFormData(prev => ({ 
            ...prev, 
            [name]: value 
        }));
        // Clear error for the specific field being edited
        if (errors[name]) {
            setErrors(prev => {
                const newErrors = {...prev};
                delete newErrors[name];
                return newErrors;
            });
        }
    }
};

const validateForm = () => {
    const newErrors = {};
    
    // Name validation
    if (!formData.name.trim()) {
        newErrors.name = "Name is required.";
    } else if (formData.name.trim().length < 2) {
        newErrors.name = "Name must be at least 2 characters long.";
    }
    
    // Email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.email.trim()) {
        newErrors.email = "Email is required.";
    } else if (!emailRegex.test(formData.email)) {
        newErrors.email = "Please enter a valid email address.";
    }
    
    // Phone validation
    const phoneRegex = /^[0-9]{10}$/;
    if (!formData.phone.trim()) {
        newErrors.phone = "Phone number is required.";
    } else if (!phoneRegex.test(formData.phone)) {
        newErrors.phone = "Please enter a valid 10-digit phone number.";
    }
    
    // Message validation
    if (!formData.message.trim()) {
        newErrors.message = "Message is required.";
    } else if (formData.message.trim().length < 10) {
        newErrors.message = "Message should be at least 10 characters long.";
    }
    
    // Services validation
    if (Object.values(checkedItems).every(v => v === false)) {
        newErrors.services = "Please select at least one service.";
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
};

const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Prevent multiple submissions
    if (isSubmitting) return;
    
    // Validate form
    if (!validateForm()) {
        setPopupMessage("Please fix the errors before submitting.");
        setPopupType("error");
        setIsPopupOpen(true);
        return;
    }
    
    // Set submission state
    setIsSubmitting(true);
    
    try {
        const response = await axios.post(
            'https://orcatechcreations.com/submit-form', // Updated domain
            {
                ...formData,
                services: checkedItems,
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                },
                timeout: 10000 // 10 second timeout
            }
        );
        
        if (response.status === 200) {
            // Reset form
            setFormData({ 
                name: "", 
                company: "", 
                phone: "", 
                email: "", 
                message: "" 
            });
            setCheckedItems({ 
                SEO: false, 
                SMOM: false, 
                PPC: false, 
                MP: false, 
                Other: false 
            });
            
            // Show success message
            setPopupMessage("Your form has been successfully submitted.");
            setPopupType("success");
            setIsPopupOpen(true);
            
            // Auto-close popup
            setTimeout(() => {
                setIsPopupOpen(false);
            }, 3000);
        }
    } catch (error) {
        console.error('Error submitting the form:', error);
        
        // Detailed error handling
        const errorMessage = 
            error.response?.data?.message ||
            (error.response?.status === 500
                ? 'Server error. Please try again later.'
                : 'Form submission failed. Please try again.');
        
        setPopupMessage(errorMessage);
        setPopupType("error");
        setIsPopupOpen(true);
    } finally {
        // Ensure submission state is reset
        setIsSubmitting(false);
    }
};
  
    
  
  const faqs = [
    { question: 'How can Orcatech Creations help my business stand out?', answer: 'We specialize in crafting personalized digital marketing strategies tailored to your business’s needs. From creating compelling content and striking visuals to executing targeted campaigns that resonate with your audience, we ensure your brand leaves a lasting impression and drives measurable growth.' },
    { question: 'What social media platforms do you specialize in?', answer: 'We manage and optimize campaigns across major social media platforms, including Facebook, Instagram, Twitter, LinkedIn, and TikTok. Our social media marketing services are designed to build your online presence, engage with your audience, and drive business growth.'},
    {question: 'How do you measure the success of digital marketing campaigns?', answer: 'We use a combination of analytics tools and KPIs such as website traffic, conversions, lead generation, and engagement metrics to track the success of each campaign. We provide regular reports, so you can see measurable results and the impact on your business growth.'},
    {question: 'Can you help with website development as part of the digital strategy?', answer: 'Yes, we offer web development services that align with your digital marketing goals. Our team designs responsive, user-friendly websites optimized for both SEO and conversions, ensuring your online presence enhances your brand’s visibility and drives traffic.'},
    {question: 'Do you provide video production services?', answer: 'Absolutely. Our media production team specializes in creating professional video content for marketing, brand promotion, and social media engagement. From concept to final edit, we ensure your videos reflect your brand’s identity and engage your target audience.'},
    {question: 'How does Orcatech Creations ensure a high ROI on campaigns?', answer: 'We implement data-driven strategies and continuously monitor and optimize campaigns to maximize returns. By focusing on targeted outreach and adjusting tactics based on performance, we ensure your investment delivers the best possible results.'},
    {question: 'What industries does Orcatech Creations work with?', answer: 'We serve businesses across a range of industries, including e-commerce, healthcare, education, technology, and more. Our experience in diverse sectors allows us to adapt our strategies to fit your specific industry’s needs and trends.'},
    {question: 'How much do Orcatech Creations services cost?', answer: 'Our service costs vary based on your specific needs and goals. We offer customized packages tailored to fit your budget. Contact us for a free consultation and receive a personalized quote based on your unique requirements.'}
    // Add more FAQs here
  ];

  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 80) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  
  return (
    <>
      <motion.div
      initial={{ opacity: 0}}
      whileInView={{ opacity: 1}}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Hero-section' ref={top}>
      <div className='navbar' ref={home}>
      <div className={`nav-container ${scrolling ? 'navbar-scroll' : ''}`}>
        <div className='orca-logo' onClick={Top}>
            <img src={logo} alt='Orcatech-Creations-logo'/>
            <h1 className='orca-loogo-text'>Orcatech Creations</h1>
        </div>
            <ul className={`nav-list ${isActive ? 'active-nav' : ''}`}>
            <li><p onClick={() => scrollToSection('home', -100)}>Home</p></li>
                <li><p onClick={() => scrollToSection('services', -100)}>Services</p></li>
                <li><p onClick={() => scrollToSection('industry', -100)}>Industries</p></li>
                <li onClick={() => scrollToSection('portfolio', -100)}>Portfolio</li>
                <li onClick={() => scrollToSection('pricing', -100)}>Pricing</li>
                <li><motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                className='nav-btn' onClick={() => scrollToSection('form', -100)}>Request a Quote</motion.button></li>
            </ul>
            </div>
                <div className='mobile'>
                  <FaBars className={`ham-svg ${isActive ? '' : 'active'}`} onClick={handleClick}/>
                  <RxCross2 className={`x-svg ${isActive ? 'active' : ''}`} onClick={handleClick}/>
                </div>
            </div>
        <div className='container'>
          <motion.div
            initial={{ opacity: 0, x: -75 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.750, delay: 0.5}}
          className='hero-container'>
            <div className='hero-tagline'><img src={heroText} alt='Meet your digital needs'/></div>
            <div className='hero-subline'>Your trusted partner for digital marketing success. We specialize in SEO, social media, performance marketing, and brand/product shoots to drive your business growth.</div>
            <motion.div
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            className='hero-btn'>
              <button onClick={Contact}>Let's Chat & Business</button>
            </motion.div>
          </motion.div>
          
          <motion.div
          initial={{ opacity: 0, x: 75 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{once: true}}
          transition={{duration: 0.750, delay: 0.5}}
          className='hero-img'>
            <img src={growth} alt='growth-image'/>
          </motion.div>
        </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, x: -75 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.75, delay: 0.5}}
       className='TurstedBy-Section'>
      <div className='trusted-container'>
      <div className='trusted-by'>
            <h1 className='trusted-heading'>Trusted by</h1>
            <div className="logo-grid-container">
              {trustedByData.map((item, index) => (
                <div key={index} className="logo-grid-item">
                  <img src={item.src} alt="company/brand-logo"/>
                </div>
              ))}
            </div>
      </div>
      </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Service-section' ref={service}>
      <div className={`main-container`} ref={srvic}>
        <div className='containerr'>
          <div className='service-heading' ref={cardhead}>Services</div>
          <div className='sub-container'>
           <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
         className={`card ${activeCard ? 'active' : ''}`}>
          <section>
            <h2>Search Engine Optimization</h2>
            <img src={icon1} alt="Search Engine Optimization" />
          </section>
          <p>SEO bridges the gap between customers and your business online.</p>
          <div className='line'></div>
          <span onClick={() => {handleCardClick('SEO'); cardHead();}}>
            <h4>Learn more</h4>
            <img className='arrow' src={arrow} alt='Arrow' />
          </span>
      </motion.div>
      {activeCard === 'SEO' && (
        <div className= 'toggle-page'>
          <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
          <SEO />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
        </div>
      )}
      <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
            className={`card ${activeCard ? 'active' : ''}`}>
            <section>
              <h2>Social Media Optimization and Marketing</h2>
              <img src={icon2} alt="Social Media Optimization and Marketing" />
            </section>
            <p>Social Media Optimization and Marketing (SMOM) is the process of improving a brand’s visibility on social media.</p>
            <div className='line'></div>
            <span onClick={() => {handleCardClick('SMOM'); cardHead();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow' />
            </span>
    </motion.div>
    {activeCard === 'SMOM' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <SMOM />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
      <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
             className={`card ${activeCard ? 'active' : ''}`}>
            <section>
              <h2>Pay Per Click</h2>
              <img src={icon3} alt="Pay Per Click" />
            </section>
            <p>PPC (Pay-Per-Click) advertising is a digital marketing model where you pay for every click your ad receives.</p>
            <div className='line'></div>
            <span onClick={() => {handleCardClick('PPC'); cardHead();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow' />
            </span>
    </motion.div>
    {activeCard === 'PPC' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <PPC />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
             className={`card ${activeCard ? 'active' : ''}`}>
            <section>
              <h2>Web Development</h2>
              <img src={icon4} alt="Web Development" />
            </section>
            <p>Web development is the process of creating and maintaining websites.</p>
            <div className='line'></div>
            <span onClick={() => {handleCardClick('WD'); cardHead();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow' />
            </span>
    </motion.div>
    {activeCard === 'WD' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <WD />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
             className={`card ${activeCard ? 'active' : ''}`}>
            <section>
              <h2>Content Marketing</h2>
              <img src={icon5} alt="Content Marketing" />
            </section>
            <p>Content marketing creates valuable content to engage and attract a specific target audience.</p>
            <div className='line'></div>
            <span onClick={() => {handleCardClick('CM'); cardHead();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow' />
            </span>
    </motion.div>
    {activeCard === 'CM' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <CM />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
             className={`card ${activeCard ? 'active' : ''}`}>
            <section>
              <h2>Video Editing</h2>
              <img src={icon9} alt="Video Editing" />
            </section>
            <p>Video editing is the process of editing videos for various purposes.</p>
            <div className='line'></div>
            <span onClick={() => {handleCardClick('VP'); cardHead();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow' />
            </span>
    </motion.div>
    {activeCard === 'VP' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <VP />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}}
            className={`card ${activeCard ? 'active' : ''}`}>
      <section>
        <h2>Graphic Design</h2>
        <img src={icon6} alt="Graphic Design" />
      </section>
      <p>Graphic design is the art of creating visual content to communicate messages and ideas.</p>
      <div className='line'></div>
      <span onClick={() => {handleCardClick('GD'); cardHead();}}>
        <h4>Learn more</h4>
        <img className='arrow' src={arrow} alt='Arrow' />
      </span>
    </motion.div>
    {activeCard === 'GD' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <GD />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}} className={`card ${activeCard ? 'active' : ''}`}>
      <section>
        <h2>Media Production</h2>
        <img src={icon7} alt="Media Production" />
      </section>
      <p>Media production involves creating, developing, and distributing content across various platforms like video, audio, and digital media.</p>
      <div className='line'></div>
      <span onClick={() => {handleCardClick('MP'); cardHead();}}>
        <h4>Learn more</h4>
        <img className='arrow' src={arrow} alt='Arrow' />
      </span>
    </motion.div>
    {activeCard === 'MP' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <MP />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.75, delay: 0.50}} className={`card ${activeCard ? 'active' : ''}`}>
      <section>
        <h2>Brand Shoot</h2>
        <img src={icon8} alt="Brand Shoot" />
      </section>
      <p>Brand shoot is a photography session designed to capture visuals that represent a brand's identity and values.</p>
      <div className='line'></div>
      <span onClick={() => {handleCardClick('BS'); cardHead();}}>
        <h4>Learn more</h4>
        <img className='arrow' src={arrow} alt='Arrow' />
      </span>
    </motion.div>
    {activeCard === 'BS' && (
      <div className= 'toggle-page'>
        <IoArrowBack className='x-svgg' onClick={() => {handleClose();}} />
        <BS />
          <motion.button
        whileHover={{ scale: 1.050 }}
        whileTap={{ scale: 0.9 }}
        className='btn-2'
         onClick={Contact}
        >I want to engage</motion.button>
      </div>
    )}
    </div>
        </div>
    </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Industry-section' ref={industry}>
        <div className={`sec-containerr`} ref={card2head}>
        <div className='service-heading'ref={ind}>Industries</div>
        <div className='sub-container'>
            <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.750, delay: 0.5}}
            className={`cards ${activeCard2 ? 'active2' : ''}`}>
              <section>
              <h2>Saas Business</h2>
              </section>
              <p>A SaaS (Software as a Service) business provides cloud-based software solutions that users can access online via a subscription model. Instead of installing software locally, customers use applications hosted by the provider, benefiting from regular updates, scalability, and reduced IT infrastructure costs.</p>
              <div className='line'></div>
              <span onClick={() => {handleCardClick2('SaaS'); card2Head();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow'/>
              </span>
            </motion.div>
            {activeCard2 === 'SaaS' && (
            <div className='toggle-page'>
            <IoArrowBack className='x-svgg' onClick={handleClose2} />
            <SaaS/>
            <motion.button
            whileHover={{ scale: 1.050 }}
            whileTap={{ scale: 0.9 }}
            className='btn-2'
             onClick={Contact}
            >I want to engage</motion.button>
            </div>
            )}
            <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.750, delay: 0.5}}
            className={`cards ${activeCard2 ? 'active2' : ''}`}>
              <section>
              <h2>B2B Business</h2>
              </section>
              <p>A B2B (Business-to-Business) business focuses on selling products or services directly to other companies, rather than to individual consumers. These transactions typically involve larger orders, longer sales cycles, and are aimed at improving operational efficiency, reducing costs, or fulfilling the needs of other businesses.</p>
              <div className='line'></div>
              <span onClick={() => {handleCardClick2('B2B'); card2Head();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow'/>
              </span>
            </motion.div>
            {activeCard2 === 'B2B' && (
            <div className='toggle-page'>
            <IoArrowBack className='x-svgg' onClick={handleClose2} />
            <B2B/>
            <motion.button
            whileHover={{ scale: 1.050 }}
            whileTap={{ scale: 0.9 }}
            className='btn-2'
             onClick={Contact}
            >I want to engage</motion.button>
            </div>
            )}
            <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.750, delay: 0.5}}
            className={`cards ${activeCard2 ? 'active2' : ''}`}>
              <section>
              <h2>B2C Business</h2>
              </section>
              <p>B2C (Business-to-Consumer) refers to businesses that sell products or services directly to individual consumers. This model focuses on meeting the needs and preferences of everyday customers, often with shorter sales cycles and more straightforward transactions compared to B2B. Common examples include retail stores, e-commerce sites, and subscription services.</p>
              <div className='line'></div>
              <span onClick={() => {handleCardClick2('B2C'); card2Head();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow'/>
              </span>
            </motion.div>
            {activeCard2 === 'B2C' && (
            <div className='toggle-page'>
            <IoArrowBack className='x-svgg' onClick={handleClose2} />
            <B2C/>
            <motion.button
            whileHover={{ scale: 1.050 }}
            whileTap={{ scale: 0.9 }}
            className='btn-2'
             onClick={Contact}
            >I want to engage</motion.button>
            </div>
            )}
            <motion.div
            initial={{ opacity: 0, y: 75 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{once: true}}
            transition={{duration: 0.750, delay: 0.5}}
            className={`cards ${activeCard2 ? 'active2' : ''}`}>
              <section>
              <h2>E-commerce</h2>
              </section>
              <p>E-commerce, or electronic commerce, is the buying and selling of goods or services over the internet. It allows businesses and consumers to conduct transactions online through websites or mobile apps, offering convenience, wider product choices, and the ability to shop from anywhere at any time. Popular examples include online retailers and marketplaces.</p>
              <div className='line'></div>
              <span onClick={() => {handleCardClick2('ECOM'); card2Head();}}>
              <h4>Learn more</h4>
              <img className='arrow' src={arrow} alt='Arrow'/>
              </span>
            </motion.div>
            {activeCard2 === 'ECOM' && (
            <div className='toggle-page'>
            <IoArrowBack className='x-svgg' onClick={handleClose2} />
            <ECOM/>
            <motion.button
            whileHover={{ scale: 1.050 }}
            whileTap={{ scale: 0.9 }}
            className='btn-2'
             onClick={Contact}
            >I want to engage</motion.button>
            </div>
            )}
          </div>
        </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Portfolio-section'>
      <div className='pContainer' ref={portfolio}>
        <div className='portfolio-heading'>Portfolio</div>
          <motion.div
          initial={{ opacity: 0, y: 75 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{once: true}}
          transition={{duration: 0.750, delay: 0.5}}
          className="grid-container">
          {mediaData.map((media, index) => (
            <div
            key={index} className="grid-item">
              {media.type === 'image' ? (
                <img src={media.src} alt={media.alt} />
              ) : (
                <video src={media.src} autoPlay muted loop/>
              )}
                </div>
              ))}
            </motion.div>
      </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='About-section'>
      <div className='container-about'>
              <div className='how-it-works-heading'>how it works</div>
              <motion.div
              initial={{ opacity: 0, y: 75 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{once: true}}
              transition={{duration: 0.750, delay: 0.5}}
              className='abt-content'>
            <div className='img-1'><img src={procees} alt='process-illustration'/></div>
            <div className='abt-ul'>
            <ul>
              <h1>Our Approach</h1>
              <li><strong>Discovery & Strategy - </strong>We begin by researching your industry and audience to create a tailored strategy.</li>
              <li><strong>Design & Development - </strong>Our creative team crafts visually stunning and user-friendly websites.</li>
              <li><strong>Marketing & Promotion - </strong>We implement marketing strategies to increase your online visibility.</li>
              <li><strong>Monitoring & Optimization - </strong>We continually analyze data to refine and optimize our efforts.</li>
            </ul>
            </div>
        </motion.div>
        <div className='Testimonial-section'>
          <motion.div
          initial={{ opacity: 0, rotate: 0}}
          whileInView={{ opacity: 1, rotate: 90 }}
          viewport={{once: true}}
          transition={{duration: 0.50, delay: 0.25}}
          className='line-svg'></motion.div>
          <motion.div
          initial={{ opacity: 0}}
          whileInView={{ opacity: 1}}
          viewport={{once: true}}
          transition={{duration: 1.75, delay: 1}}
          className='head-line'>Testimonials</motion.div>
          <motion.div
          initial={{ opacity: 0, y: 75}}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{once: true}}
          transition={{duration: 0.50, delay: 0.25}}
          className='test-card'>
          {CardData.map((card, index) => (
            <Testimonial 
              key={index}
              rating={card.rating}
              rating1={card.rating1}
              rating2={card.rating2}
              rating3={card.rating3}
              rating4={card.rating4}
              customerName={card.customerName}
              description={card.description} 
            />
          ))}
          </motion.div>
          <motion.div
          initial={{ opacity: 0, rotate: 0}}
          whileInView={{ opacity: 1, rotate: 90 }}
          viewport={{once: true}}
          transition={{duration: 0.50, delay: 0.25}}
           className='line-svg' style={{marginTop:'3.5em'}}></motion.div>
          </div>
        </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75}}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      ref={form}
      className="Form-section">
      <div className='form' ref={engage}>
      <motion.div
       initial={{ opacity: 0, y: 75}}
       whileInView={{ opacity: 1, y: 0 }}
       viewport={{once: true}}
       transition={{duration: 0.750, delay: 0.5}}
       className='form-container' ref={formm}>
      <div className='form-body'>
      <h2 className='headline-tag'>Ready to Scale Your Business?</h2>
      <img src={formTut} alt='image-of-lady-with-device' />
      </div>
      <form onSubmit={handleSubmit}>
      <div className="topForm">
        <label>
          Name *<br />
          <input
            type="text"
            name="name"
            placeholder="Type your full name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          {errors.name && <p className="error">{errors.name}</p>}
        </label>
        <br />
        <label>
          Company *<br />
          <input
            type="text"
            name="company"
            placeholder="Type your company name"
            value={formData.company}
            onChange={handleChange}
          />
        </label>
        <br />
        <label>
          Phone<br />
          <input
            type="tel"
            name="phone"
            placeholder="Type your phone number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
          {errors.phone && <p className="error">{errors.phone}</p>}
        </label>
        <br />
        <label>
          Email Address *<br />
          <input
            type="email"
            name="email"
            placeholder="Type your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          {errors.email && <p className="error">{errors.email}</p>}
        </label>
        <br />
        <label>
          How can we help you? *<br />
          <textarea
            name="message"
            placeholder="A brief description here"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          {errors.message && <p className="error">{errors.message}</p>}
        </label>
        <br />
      </div>
      <div className="bottomForm">
        <label>
          <input
            type="checkbox"
            name="SEO"
            className="custom-checkbox"
            checked={checkedItems.SEO}
            onChange={handleChange}
          />
          Search Engine Optimization
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="SMOM"
            className="custom-checkbox"
            checked={checkedItems.SMOM}
            onChange={handleChange}
          />
          Social Media Optimization and Marketing
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="PPC"
            className="custom-checkbox"
            checked={checkedItems.PPC}
            onChange={handleChange}
          />
          Pay Per Click
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="MP"
            className="custom-checkbox"
            checked={checkedItems.MP}
            onChange={handleChange}
          />
          Media Production
        </label>
        <br />
        <label>
          <input
            type="checkbox"
            name="Other"
            className="custom-checkbox"
            checked={checkedItems.Other}
            onChange={handleChange}
          />
          Other
        </label>
        <br />
      </div>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        type="submit"
      >
        Send Message
      </motion.button>
      </form>
            {isPopupOpen && (
                <div className="popup-overlay">
                    <div className="popup-content">
                        <h2>{popupType === "success" ? "Thank you!" : "Error"}</h2>
                        <p>{popupMessage}</p>
                        <button onClick={closePopUp}>Close</button>
                    </div>
                </div>
            )}
          </motion.div>
          </div>
      </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75}}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Pricing-section' ref={pricing}>
      <div className="pricing-container">
        <h1 className='pricing-heading'>Pricing</h1>
        <div className="pricing-details">
      {pricingDetails.map((plan, planIdx) => (
        <motion.div
        initial={{ opacity: 0, y: 75}}
       whileInView={{ opacity: 1, y: 0 }}
       viewport={{once: true}}
       transition={{duration: 0.750, delay: 0.5}}
        key={planIdx} className="plan-details">
        <h1 className='pricing-headings'>{plan.name}</h1>
          {plan.planDetails.map((section, sectionIdx) => (
            <section key={sectionIdx} className='card-body'>
              <h1 className="pricing-card-heading">{section.planName}</h1>
              <section>
                {section.sections.map((detail, detailIdx) => (
                  <span key={detailIdx} className="card-info">
                    <img src={detail.icon} alt="box-icon" />
                    <p> {detail.text} </p>
                  </span>
                ))}
              </section>
            </section>
          ))}
        </motion.div>
      ))}
    </div>
    </div>
    </motion.div>
      <motion.div
      initial={{ opacity: 0, y: 75}}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='FAQ-section'>
      <dl className='faq-container'>
        <h1>Frequently asked questions</h1>
      {faqs.map((faq, index) => (
        <FAQ faq={faq} key={index} />
      ))}
      <section>
      <p className='help-phrase'>Still have questions? We're here to help</p>
      <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      className='contact-us-btn'  onClick={Contact}>Contact Us</motion.button>
      </section>
    </dl>
      </motion.div>
      <motion.div
      initial={{ opacity: 0}}
      whileInView={{ opacity: 1}}
      viewport={{once: true}}
      transition={{duration: 0.50, delay: 0.25}}
      className='Footer-section'>
      <div className='footer'>
        <div className='footer-top'>
        <div className='logo-container' onClick={Top}>
            <img src={logo} alt='orcatech-creations-logo'/>
            <h1>Orcatech Creations</h1>
        </div>
        <div className='menu-container'>
            <div className='menu-item'>
                <h1 onClick={Srvc}>Services</h1>
                <ul>
                    <li>Search Engine Optimization</li>
                    <li>Social Media Optimization and Marketing</li>
                    <li>Pay Per Click</li>
                    <li>Web Development</li>
                    <li>Content Marketing</li>
                    <li>Video Editing</li>
                    <li>Graphic Design</li>
                    <li>Media Production</li>
                    <li>Brand Shoot</li>
                </ul>
            </div>
            <div className='menu-item'>
                <h1 onClick={Ind}>Industries</h1>
                <ul>
                    <li>SaaS Business</li>
                    <li>B2B Business</li>
                    <li>B2C Business</li>
                    <li>eCommerce</li>
                </ul>
            </div>
            <div className='menu-item'>
                <h1>Contact us</h1>
                <ul>
                    <li><a href='mailto:orcatechcreations@orcatechcreations.com'>orcatechcreations@orcatechcreations.com</a></li>
                    <li><a href='tel:+919799019955'>+91 9799019955</a></li>
                    <li><a href='mailto:hr@orcatechcreations.com'>hr@orcatechcreations.com (Join our team)</a></li>
                </ul>
            </div>
        </div>
        
    </div>
    <div className='footer-line'></div>
    <div className='footer-bottom'>
      <motion.div
      initial={{ opacity: 0, y: -50 }}
      animate={{
        opacity: [0, 1, 0],
        y: [-50, 0, -50],
      }}
      transition={{
        duration: 1.5,
        delay: 0.25,
        repeat: Infinity,
        repeatType: "loop",
      }}
      className={`scroll-down ${isPpActive ? 'close' : ''}`}><FaArrowDown /></motion.div>
        <div className='copy'><span style={{color:'#C4363F'}} onClick={()=>{handleClickPP();}}>Privacy Policy</span> | © 2024 Orcatech Creations All rights reserved</div>
        <div className='social-icon'>
            <li><a href='https://www.linkedin.com/company/orca-creationss/' target='_blank' rel="noreferrer"><img src={linkedInIcon} alt='social-media-icon'/></a></li>
            <li><a href='https://www.instagram.com/orcatechcreations/' target='_blank' rel="noreferrer"><img src={instagramIcon} alt='social-media-icon'/></a></li>
            <li><a href='https://www.facebook.com/OrcatechCreations/' target='_blank' rel="noreferrer"><img src={facebookIcon} alt='social-media-icon'/></a></li>
            <li><a href='https://x.com/orcatech_' target='_blank' rel="noreferrer"><img src={xIcon} alt='social-media-icon'/></a></li>
        </div>
    </div>
    </div>
      </motion.div>
      <div className={`pp-content ${isPpActive ? 'close' : ''}`}>
        <RxCross2 className='close-btn' onClick={handleClickPP}/>
        <h1>Privacy Policy</h1>
        <p>Orcatech Creations is committed to protecting the privacy of our website visitors. Your trust is important to us, and we are dedicated to handling your personal information carefully. Below, we explain how we collect and use your information when you visit our website.</p>
        <h3>Voluntary Information</h3>
        <p>We do not require personal information to access most of our website. However, we collect personal information voluntarily, including your name, phone number, email address, and other relevant details. This may include information you provide through comments, questions, or any interactive services available on the website.</p>
        <h3>Use of Information</h3>
        <p>Personal information collected is used for various business purposes, including:</p>
        <ul>
          <li>- Providing information or services via the website.</li>
          <li>- Sending requested information or services via email or post.</li>
          <li>- Seeking feedback from visitors or contacting you regarding services offered on the site.</li>
          <li>- Sending marketing communications (with your consent), such as promotional offers, newsletters, or other updates about our services. You can opt out at any time.</li>
        </ul>
        <h3>Cookies and Tracking Technologies</h3>
        <p>We use cookies and similar tracking technologies to enhance your experience on our website, analyze site usage, and tailor marketing efforts. Cookies are small files that are placed on your device when you visit our site. You can control cookie settings through your browser, but please note that disabling cookies may impact your ability to use some features of the website.</p>
        <h2>We may also use third-party analytics services, such as Google Analytics, to track and analyze website traffic.</h2>
        <h3>Data Accuracy</h3>
        <p>We strive to treat your information with care and maintain its accuracy. If you believe the information we hold is incorrect or incomplete, please contact us at your earliest convenience so we can make the necessary corrections.</p>
        <h3>Data Sharing and Third-Party Access</h3>
        <p>Service Providers: To assist with our services, we may share your data with trusted third-party providers, such as payment processors or cloud storage services. These providers are contractually obligated to handle your data securely and under our privacy practices.</p>
        <p>Legal Disclosure: We may disclose your data if required by law or in response to valid legal requests, such as a subpoena, legal proceedings, or regulatory investigation.</p>
        <h3>Data Retention</h3>
        <p>We retain personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law. If you wish to delete your personal information, please contact us, and we will take reasonable steps to remove it from our records.</p>
        <h3>Data Security</h3>
        <p>We take the security of your personal information seriously. We implement appropriate technical and organizational measures to protect your data from unauthorized access, disclosure, alteration, or destruction. However, no method of transmission over the Internet or electronic storage is 100% secure, and we cannot guarantee absolute security.</p>
        <h3>International Data Transfers</h3>
        <p>If you are located outside of [Country], please note that we may transfer and process your personal information in other countries, including those outside the European Economic Area (EEA) or other jurisdictions with different data protection laws. By using our services, you consent to such transfers.</p>
        <h3>Children’s Privacy</h3>
        <p>Our website is not intended for children under the age of 13. We do not knowingly collect or solicit personal information from children under 13. If we learn that we have collected personal information from a child under 13 without verification of parental consent, we will delete that information as quickly as possible.</p>
        </div>
    </>
  );
}

export default App;
