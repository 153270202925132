import React from 'react'
import './SEO.css'

export default function VP() {
  return (
    <>
    <div className='box'>
        <div className='Container'>
          <h1>Video Editing</h1>
          <br/><span><h3>Elevate Your Message with Professional Videos.</h3><br/>
          Whether you're looking to showcase your products, educate your audience, or enhance your brand image, our expert video editing team can help. We'll create compelling visuals that captivate your audience and drive results.<br/><br/>
          <ul>
            <h3>Basic Video Editing:</h3>
            <li><strong>Video Cutting and Trimming: </strong>Removing unnecessary parts and structuring the footage.</li>
            <li><strong>Video Speed Control: </strong>Adjusting the speed of footage for fast-motion or slow-motion effects.</li>
            <li><strong>Video Conversion: </strong>Changing video file formats for compatibility across various platforms.</li>
          </ul><br/>
          <ul>
            <h3>Color and Visual Enhancements:</h3>
            <li><strong>Color Correction and Grading: </strong>Enhancing and adjusting colors to maintain consistency and achieve a particular mood or style.</li>
            <li><strong>Video Stabilization: </strong>Smoothing out shaky footage to improve video quality.</li>
          </ul><br/>
          <ul>
            <h3>Special Graphics:</h3>
            <li><strong>Motion Graphics and Titles: </strong>Creating dynamic text animations, lower-thirds, and graphic elements to enhance video presentation.</li>
            <li><strong>Green Screen Editing: </strong>Replacing the green screen with custom backgrounds or environments.</li>
          </ul><br/>
          <ul>
            <h3>Audio Editing:</h3>
            <li><strong>Audio Editing and Mixing: </strong>Syncing, balancing, and enhancing audio tracks, sound effects, and background music.</li>
            <li><strong>Video Dubbing and Voiceover: </strong> Replacing or adding narration, voiceovers, or new dialogue.</li>
          </ul><br/>
          <ul>
            <h3>Subtitles and Accessibility:</h3>
            <li><strong>Video Captioning and Subtitling: </strong>Adding captions or subtitles to improve accessibility and comprehension for diverse audiences.</li>
          </ul>
          </span>
        </div>
    </div>
    </>
  )
}
