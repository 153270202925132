import React from 'react'
import diagram from './Funnel.png'

export default function B2C() {
  return (
    <div className='indBox'>
        <div className='indContainer'>
            <h1>B2C Industry</h1>
            <p>
            When it comes to engaging directly with consumers, the <strong>B2C (Business to Consumer)</strong> industry takes center stage.<br/>
            <br/>Currently, the global B2C e-commerce market is projected to reach <strong>$6.3 trillion</strong> by 2024, driven by a surge in online shopping and consumer preferences for convenience. However, despite this growth, <strong>about 30% of B2C businesses</strong> struggle to remain competitive in a saturated market. What leads to this?
            </p>
            <br/>
            <ul>
                <h4>For B2C companies, it’s vital to address several core questions:</h4>
                <li>Who is your target audience?</li>
                <li>What motivates their purchasing decisions?</li>
                <li>How does your brand resonate with their lifestyle?</li>
            </ul><br/>
            <ul>
                <h4>The growth trajectory of a B2C company can be outlined in four main stages:</h4>
                <li>
                <strong>Awareness:</strong> At this initial stage, companies attract potential customers through advertising, social media, and search engine optimization.<br/>
                <table>
                    <thead>
                        <tr>
                        <th>Marketing Channel</th>
                        <th>Reach and Engagement</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Social Media</td>
                        <td>54% consumer engagement</td>
                    </tr>
                    <tr>
                        <td>Email Marketing</td>
                        <td>42% conversion rate</td>
                    </tr>
                    </tbody>
                </table>
                </li>
                <li><strong>Engagement:</strong> Here, businesses focus on personalized marketing strategies that resonate with individual consumers to foster deeper connections.</li>
                <li><strong>Conversion:</strong> The aim is to streamline the purchase process, encouraging quick decision-making and purchases.</li>
                <li><strong>Retention:</strong> After a purchase, fostering loyalty through exceptional customer service, targeted promotions, and engagement strategies is essential for ongoing success.</li>
            </ul>
            <br/><p>Businesses can employ marketing models like the AIDA funnel, focusing on creating awareness, generating interest, nurturing desire, and prompting action through clear calls-to-action.</p>
            <img src={diagram} alt='aida-funnel-diagram'/>
        </div>
    </div>
  )
}
