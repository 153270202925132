import React from 'react'
import { motion } from "framer-motion"
import './component/Testimonial.css'

const Testimonial = ({rating, rating1, rating2, rating3, rating4, customerName, description}) => {
    return (
      <motion.div
      whileHover={{ scale: 1.050 }}
      whileTap={{ scale: 0.9 }}
      className='card-container'>
        <div className="cardss">
          <div>
          <img src={rating} alt='Rating-star'/>
          <img src={rating1} alt='Rating-star'/>
          <img src={rating2} alt='Rating-star'/>
          <img src={rating3} alt='Rating-star'/>
          <img src={rating4} alt='Rating-star'/>
          </div>
            <p>{description}</p>
            <h2>{customerName}</h2>
        </div>
      </motion.div>
    );
  }
  
export default Testimonial;
