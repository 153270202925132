import React from 'react'
import './SEO.css'

export default function GD() {
  return (
    <>
    <div className='box'>
        <div className='Container'>
          <h1>Graphic Design</h1>
          <br/><span>
          In our increasingly digital world, personal interactions are becoming less common. This presents a unique opportunity for your business to stand out and create a lasting impression on your audience.<br/><br/>
          <br/>
          <ul>
            <h3>Branding & Identity Design:</h3>
            <li><strong>Logo Design: </strong>Crafting a unique and recognizable logo that represents the brand.</li>
            <li><strong>Brand Guidelines: </strong>Developing a cohesive visual identity including color schemes, typography, and design elements for consistent branding.</li>
          </ul><br/>
          <ul>
            <h3>Social Media Design:</h3>
            <li><strong>Social Media Post Graphics: </strong>Creating visually appealing posts, stories, and highlights for platforms like Instagram, Facebook, LinkedIn, etc.</li>
            <li><strong>Banner & Cover Design: </strong>Designing cover images and banners for social media profiles and campaigns.</li>
            <li><strong>Social Media Ad Design: </strong>Designing eye-catching visuals for paid ads on social media platforms.</li>
          </ul><br/>
          <ul>
            <h3>Website & UI Design:</h3>
            <li><strong>Website Layout Design: </strong>Crafting the visual layout and user interface for websites, ensuring a seamless user experience.</li>
            <li><strong>Landing Page Design: </strong>Designing high-conversion landing pages for product promotions, email signups, or campaigns.</li>
          </ul><br/>
          <ul>
            <h3>Email Marketing Design:</h3>
            <li><strong>Email Template Design: </strong>Creating custom, visually appealing email templates for newsletters and email marketing campaigns.</li>
            <li><strong>Email Graphics: </strong>Developing icons, banners, and visuals to enhance the email content.</li>
          </ul><br/>
          <ul>
            <h3>Infographics Design:</h3>
            <li><strong>Data Visualization: </strong>Designing infographics to present complex data or information in an easily digestible, visual format.</li>
          </ul><br/>
          <ul>
            <h3>Digital Advertising Design:</h3>
            <li><strong>Banner Ad Design: </strong>Creating visuals for display ads on websites or ad networks like Google Ads.</li>
            <li><strong>PPC Ad Creatives: </strong>Designing pay-per-click ad visuals optimized for clicks and conversions.</li>
          </ul><br/>
          <ul>
            <h3>Print Design:</h3>
            <li><strong>Brochures & Flyers: </strong>Designing brochures, leaflets, and pamphlets for digital and print distribution.</li>
          </ul><br/>
          <ul>
            <h3>Motion Graphics:</h3>
            <li><strong>Video Graphics: </strong>Creating animated elements for videos, including lower thirds, titles, and transitions.</li>
            <li><strong>Explainer Video Graphics: </strong>Designing motion graphics to support explainer or promotional videos.</li>
          </ul>
          </span>
        </div>
    </div>
    </>
  )
}
